  .cabecalho{
    min-width: 100%;
    max-width: 1920px;
    height: 150px;
    box-shadow: 0 2px 7px orangered;
    border-bottom: 1px solid white;
    display: flex;
    background: white;
    .imagem{
      display: flex;
      width: 60%;
      justify-content: flex-end;
    }
    #User{
      align-self: flex-end;
      display: grid;
      margin-left: 100px;
      width: 400px;
      grid-template-rows: 40px 40px 40px;
      .input{
        border: 1px solid rgb(255, 108, 22);
        border-radius: 4px;
        height: 20px;
        outline: none;
      }
      span{
        align-self: flex-end;
        font-weight: 500;
        align-self: center;
      }
      button{
        background-color: rgb(255, 108, 22);
        border-radius: 4px;
        color: white;
        height: 25px;

        &:hover{
          filter: brightness(0.9);
        }
      }
    }
    .rotaitems{
      width: min(100%);
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 10px;
      
      >div{
        cursor: pointer;
      }
      .routeItens{
        font-weight: bold;
        color: #73737e;
        text-decoration: none;
        width: 100px;
        height: 40px;
        gap: 10px;
        &:hover{
          color: orangered;
        }
      }
    }
    button{
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  @media only screen and(max-width: 414px){
    .cabecalho{
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100vw;
      width: auto;
      max-width: 414px;
      height: max-content;

      #User{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        input{
          width: 50%;
        }
        button{
          width: 50%;
        }
      }

      .rotaitems{
        min-width: 50%;
        width: auto;
        max-width: 100%;

        .routeItens{
          width: 90px;
          text-align: center;
          font-size: 15px;
        }
      }
    }
  }

@media only screen and(max-width: 320px){
  .cabecalho{
    .rotaitems{
      .routeItens{
        font-size: 13px;
      }
    }
  }
}