.login{
  min-width: 680px;
  width: auto;
  max-width: 7680px;
  height: 657px;
  justify-content: center;
  align-items: center;
  display: flex;
  div{
    background-color: white;
    width: 300px;
    justify-content: center;
    display: grid;
    grid-template-rows: 100%;
    row-gap: 5px;
    height: 350px;
    box-shadow: 0 1px 7px orangered;
    border-radius: 8px;
    border: 1px solid orangered;
    grid-template-columns: 100%;
    .areabutton{
      display: grid;
      grid-template-rows: 150px 25px 25px 25px;
      width: 100%;
      h2{
        align-self: center;
        justify-self: center;
        margin: 0;
        font-size: 22px;
        color: rgb(255, 108, 22);
      }
      label{
        align-self: flex-end;
        justify-self: flex-start;
        font-weight: bold;
        font-size: 13px;
        color: gray;
        margin-left: 30px;

      }
      .inputsdeacesso{

        border: 1px solid gray;
        border-radius: 4px;
        justify-self: center;
        width: 80%;
        height: 20px;
        outline: none;
        transition: border 0.15s linear 0.15s;
        
        &:focus{
          border: 1px solid rgb(255, 108, 22);
          color: rgb(255, 108, 22);
        }

      }

      .popup{
        width: 100px;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
        text-align: center;
        position: absolute;
        align-self: flex-end;
        margin-bottom: 110px;
        margin-right: 10px;
        justify-self: flex-end;
        font-size: 11px;
        background-color: #313131;
        color: #fff
      }

      #entrar{
        height: 30px;
        justify-self: center;
        border: 1px solid;
        border-radius: 5px;
        cursor: pointer;
        background-color: rgb(255, 108, 22);
        color: white;
        width: 200px;
        transition: transform 0.10s linear 0.10s, filter 0.10s linear 0.10s;
        &:hover{
          transform: scale(1.1);
          filter: brightness(0.9);
        }

      }
    }
 }
}
@media only screen and(min-width: 1920px){
  .login{
    height: min(937px);
  }
}

@media only screen and(max-width: 414px){
  .login{
    min-width: 280px;
    width: auto;
    max-width: 414px;

    .imagem{
      width: 0px;
    }

    .rotaitems{
      max-width: 414px;
    }
  }
}