#main{
    display: grid;
    grid-template-rows: 152px auto;
    width: 100%;
    gap: 10px;
    #detalhesarea{
        display: flex;
        justify-content: space-around;
        #dataarea{
            align-self: center;
            display: grid;
            justify-self: center;
            display: grid;
            width: 380px;
            height: 450px;
            background-color: white;
            border: 1px solid;
            border-color: orangered;
            border-radius: 5px;
            h2{
                justify-self: center;
            }
            #endereco{
                justify-self: center;
                text-align: center;
                width: 100%;
            }
            span{
                &:nth-of-type(1){
                    justify-self: center;
                }
                &:nth-of-type(2){
                    justify-self: center;
                }
                &:nth-of-type(3){
                    justify-self: center;
                }
                &:nth-of-type(4){
                    justify-self: center;
                }
            }
            p{
                text-align: center;
            }
        }
        #formcliente{
            display: grid;
            align-self: center;
            height: 300px;
            width: 380px;
            text-align: center;
            grid-template-rows: 100px 40px 40px 20px 40px 20px 40px;
            input{
                height: 30px;
                width: 60%;
                justify-self: center;
                border-radius: 5px;
                border-width: 1px;
                border-style: solid;
                border-color: orangered;
                outline: none;
            }
            select{
                height: 30px;
                width: 60%;
                justify-self: center;
                border-radius: 5px;
                border-width: 1px;
            }
            span{
                align-self: flex-end;
            }
        }
        #listaclientes{
            align-self: center;
            height: 450px;
            width: 380px;
            text-align: center;
            overflow-y:  auto;
            #arealistaclientes{
                display: grid;
                row-gap: 5px;
                text-align: left;
                .nomeclientes{
                    background-color: white;
                    border: 1px solid orangered;
                    padding-left: 5px;
                }
            }

        }
        button{
            justify-self: center;
            height: 40px;
            width: 200px;
            background-color: orangered;
            border: orangered 1px solid;
            border-radius: 5px;
            color: white;
            cursor: pointer;
            &:hover{
                filter: brightness(0.8);
            }
        }
        #addclientebutton:disabled{
            background-color: gray;
            border-color: gray;
        }
    }
}

@media only screen and(max-width: 1173px){
    #detalhesarea{
        flex-direction: column;
        gap: 50px;
        width: 100vw;
        #formcliente{
            min-width: 80vw;
            max-width: 90%;
     
       }
       #dataarea{
        max-width: 90%;
       }
       #listaclientes{
        max-width: 90%;
       }
    }
}