body{
  background-color: rgb(248, 245, 245);
  margin: 0px;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  align-items: center;
  height: min(657px);
  min-width: 680px;
  width: auto;
  max-width: 7680px;

  #root{
    min-width: 680px;
    width: auto;
    max-width: 7680px;
    height: min(657px);
  }
}
@media only screen and(min-width: 1920px){
  body{
    height: min(937px);

    #root{
      height: min(937px);
    }
  }

}

@media only screen and(max-width: 414px){
  body{
    height: min(657px);
    min-width: 280px;
    max-width: 414px;
    width: auto;
    #root{
      height: min(657px);
      min-width: 280px;
      width: auto;
      max-width: 414px;
    }
  }
}