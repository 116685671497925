#mainarea{
  display: grid;
  grid-template-columns: minmax(680px, 7680px);
  justify-content: center;
  min-width: 680px;
  width: auto;
  max-width: 7680px;
  height: max-content;
  h1{
    color: #73737e;
    justify-self: center;
    font-size: 50px;
  }
  #procurar{
    justify-self: center;
    outline: none;
    width: 300px;
    height: 25px;
    font-size: 10pt;
    border: 1px solid rgb(255, 108, 22);
    border-radius: 5px;  
    color: orangered;
    font-weight: bold;
    margin-bottom: 5px;
    
  }
  .history-area{
    display: grid;
    grid-template-columns: 410px 410px 410px;
    grid-auto-rows: 310px;
    color: #73737e;
    font-weight: 600;
    align-self: center;
    column-gap: 5px;
    justify-content: center;
    .dataItems{
      cursor: default;
      border-radius: 5px;
      margin: 5px;
      width: 400px;
      border: 1px solid orangered;
      background-color: white;
      display: grid;
      height: 300px;
      grid-template-rows: 35px 150px 70px;
      box-shadow: 0 1px 8px orangered;
        span{
          justify-self: center;
          font-size: 14pt;
          display: flex;
          width: 400px;
          justify-content: center;
        }
        ul{
          display: block;
          list-style-type: disc;
          margin-block-start: 1em;
          padding-inline-start: 40px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          li{
            margin-bottom: 5px;
          }
          li + li{
            margin-bottom: 5px;
          }
        }
        #areatexto{
          font-size: 10px;
          outline: none;
          font-weight: bolder;
          text-align: center;
          border: none;
          background-color: transparent;
          resize: none;
          padding: 5px;
          width: 360px;
          height: 60px;
          margin: 0px;
          word-break: break-all;
        }
        a{
          text-decoration: none;
          color: orangered;
          justify-self: center;
          
        }
   }
  }
}
@media only screen and(max-width: 414px){
    #mainarea{
      grid-template-columns: minmax(320px, 414px);
      min-width: 320px;
      max-width: 414px;

      h1{
        font-size: 25px
      }

      .history-area{
        width: 100%;
        grid-template-columns: 90%;
        .dataItems{
          width: 100%;
          grid-template-columns: minmax(100%, 100%);
          height: 280px;
          span{
            width: 100%;
            font-size: 10pt;
            align-self: center;
          }
          ul{
            font-size: 12px;
            padding-left: 20px;
          }
          #areatexto{
            width: 100%;
            padding: 0px;
          }
        }
      }
    }
}